import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import storeInstance from "../views/UserStore";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "90%",
    width: "90%"
  },
  aboutUs: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "65%",
    width: "65%",
  },
  login: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "65%",
    width: "65%",
  },
  tekst: {
    color: theme.palette.primary.white,
    marginBottom: 0
  },
  gridara:{
    height: "100%"
  },
  collection:{
    height: "75%",
    width: "75%"
  }
}));

export function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        alignContent="flex-start"
        style={{ minHeight: "8vh", maxHeight: "30vh" }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={3} className={classes.gridara}>
          <Link to="/">
          <img src={require("../images/logo.jpg")} className={classes.paper} />
          </Link>
        </Grid>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={3} alignItems="center" justify="center">
          <Typography
            className={classes.tekst}
            variant="h5"
            align="center"
            gutterBottom
          >
            {storeInstance.user !== "" ? (
              <Typography variant="h5" align="center" gutterBottom>
                {storeInstance.user}
              </Typography>
            ) : (
              <Link to="/login" style={{ color: "white" }}>
                <img
                  src={require("../images/login.jpg")}
                  className={classes.login}
                />
              </Link>
            )}
          </Typography>{" "}
        </Grid>
        <Grid item xs={12} sm={3} alignItems="center" justify="center">
          <Typography
            className={classes.tekst}
            variant="h4"
            align="center"
            gutterBottom
          >
            {storeInstance.user == "" ? (
              <img
                src={require("../images/aboutus.jpg")}
                className={classes.aboutUs}
              />
            ) : (
              <Link to={"/store"}>
              <img
                src={require("../images/mycollection.jpg")}
                className={classes.collection}
              />
              </Link>
            )}
          </Typography>{" "}
        </Grid>
      </Box>
    </div>
  );
}
