import { Header } from "../utils/Header";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Clock } from "../utils/Clock";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "../App.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";


export default function CenteredGrid() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const white = require("../images/sk1.jpg");
  const art = { white };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={4}>
          <Card className={classes.pictureGrid}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              image={art.white}
              title="Contemplative Reptile"
              className={classes.slika}
            />
          </Card>
          <Card className={classes.odjeljak}>
            <hr />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Art name
              </Typography>
              <Typography gutterBottom variant="h7" component="h7">
                Artist Name
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a
                nibh magna.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2} direction="column">
          <Grid className={classes.buttonsGrid}>
            <Button className={classes.auctionButton}>
              {" "}
              <Clock />
            </Button>
          </Grid>
          <Grid bottomPadding={0} className={classes.buttonsGrid}>
            <Button
              className={classes.buyoutButton}
              onClick={() => handleClickOpen()}
            >
              <h1>Buyout</h1>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={3} />
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Auction won"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Congradulations, you have won the auction.
           Please contact the artists for getting more information about the meeting place for the art exchange. 
           E-mail: lorem.ipsum@sit.dolor
           Phone number: 099 9999 999 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Link to={{ pathname: `/store` }}>
          <Button onClick={handleClose} color="primary" autoFocus>
            Buy
          </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main.black,
    },
  },
  resetka: {
    color: theme.palette.text.secondary.main,
  },
  buttonsGrid: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingInlineEnd: theme.spacing(0),
    height: theme.spacing(33),
    textAlign: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  pictureGrid: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingInlineEnd: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    textAlign: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  auctionButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.darker
    }
  },
  buyoutButton: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.darker
    }
  },
  slika: {
    height: 540,
  },
  odjeljak: {
    marginTop: theme.spacing(2),
  },
}));

export function Item() {
  return (
    <div>
      <Header />
      <React.Fragment>
        <CenteredGrid />
      </React.Fragment>
    </div>
  );
}

export function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
