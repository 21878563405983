import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export function Footer() {
  const classes = useStyles();

  return (<div>
    <hr/>

      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          FINE ART SELL OFF
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          All credits goes to artists who exibit their creations in this majestic website!
        </Typography>
    </footer>
    </div>
  );
}
