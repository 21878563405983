import React, { useState, useEffect } from "react";
import { Header } from "../utils/Header";
import { Footer } from "../utils/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Icon } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Slide from 'react-reveal/Slide';


const cards = [1];

export function Category() {
  const classes = useStyles();

  const white = require("../images/sk1.jpg");
  const black = require("../images/sk2.jpg");
  const art = { white, black };

  const keys = Object.keys(art);
  const randomIndex = keys[Math.floor(Math.random() * keys.length)];
  const item = art[randomIndex];

  const [image, setImage] = useState(art.white);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <React.Fragment>
      <Header />
      <main>
        <Grid className={classes.gridara} container spacing={6}>
          {
            <IconButton
              onClick={() => setImage(item)}
              variant="contained"
              color="primary"
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
          }

          {cards.map((card) => (
            <Grid item key={card} xs={12} sm={6} md={6}>
              <Slide bottom duration="500">
              <Link to={{ pathname: `/item/${card}` }}>
                {/* <CardContent className={classes.cardContentUp}>
                  {" "}
                  Expires soon
                </CardContent> */}
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justify="center"
                      style={{ minHeight: "4vh" }}
                    >
                      <Typography
                        style={{ marginTop: "2vh" }}
                        variant="h2"
                        component="h2"
                      >
                        Sit dolor
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Link>
              </Slide>
            </Grid>
          ))}
          <IconButton
            onClick={() => setCount(count + 1)}
            variant="contained"
            color="primary"
          >
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </Grid>
      </main>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    width: "100%",
    height: "100%",
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    padding: 0,
    justifyContent: "center",
    flexGrow: 1,
    backgroundColor: theme.palette.primary.white,
    alignItems: "center",
  },
  cardContentUp: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(72),
    marginBottom: theme.spacing(1),
    padding: 0,
    paddingLeft: theme.spacing(2),
    justifyContent: "center",
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  gridara: {
    justifyContent: "center",
  },
}));
