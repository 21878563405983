import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Header } from "../utils/Header";
import { Footer } from "../utils/Footer";
import Box from "@material-ui/core/Box";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Fade from "react-reveal/Fade";


const card1 = { name: "Sculptures", image: require("../images/sk1.jpg") };
const card2 = { name: "Paintings", image: require("../images/paintings.jpg") };
const card3 = { name: "Pottery", image: require("../images/pottery.jpg") };
const card4 = {
  name: "Photography",
  image: require("../images/photography.jpg"),
};
const cards = [card1, card2, card3, card4];

export function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <header>
        <Header />
      </header>
      <main>
        <Box
          display="flex"
          alignContent="flex-start"
          className={classes.cardGrid}
          spacing={2}
        >
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
            style={{ minHeight: "70vh" }}
          >
            {cards.map((card,i) => (
              <Grid item key={card} xs={6} sm={3}>
                {i % 2 == 0 ? 
                <Fade duration="1500" top>
                    <Link to={{ pathname: `/category/${card.name}` }}>
                  <Box item key={card} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={card.image}
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {card.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </Link>
                </Fade> : 
                <Fade  duration="1500" bottom>
                  <Link to={{ pathname: `/category/${card.name}` }}>
                  <Box item key={card} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={card.image}
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {card.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </Link>
                  </Fade>}
                
              </Grid>
            ))}
          </Grid>
        </Box>
      </main>
      {/* Footer */}
      <footer>
        <Footer />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  containerGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    margin: 0,
  },
  cardGrid: {
    flexDirection: "row",
    margin: theme.spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  cardMedia: {
    height: "50vh",
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));