import React, { useState, useEffect } from 'react';

export function Clock(props) {
  const [date, setDate] = useState(new Date());

 //Replaces componentDidMount and componentWillUnmount
 useEffect(() => {
  var timerID = setInterval( () => tick(), 1000 );

  return function cleanup() {
      clearInterval(timerID);
    };
 });

   function tick() {
    setDate(new Date());
   }

   return (
      <div>
        <h1>2000$</h1>
        <h1>Bid: {2000* 1.2}$</h1>
        <h2>Exp. {date.toLocaleTimeString()}.</h2>
      </div>
    );
}