import { decorate, observable } from "mobx";

 class UserStore {
    
    user = "";
    
    setUser(userName){
        this.user = userName
    }
}

decorate(UserStore, {
    user: observable,
})

const storeInstance = new UserStore()
export default storeInstance;