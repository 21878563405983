import React, { Component }  from 'react';
import './App.css';
import {SignInSide} from './views/SignInSide';
import {BrowserRouter as Router,Switch, Route} from 'react-router-dom';
import {Category} from './views/Category';
import {Item} from './views/Item';
import {Store} from './views/Store';
import {Album} from './views/Album';



function App() {

  return (

    <Router>
     <Switch>
      <Route exact path="/" component={Album}/>
      <Route path="/login" component={SignInSide}/>
      <Route path="/category/:id" component={Category}/>
      <Route path="/item" component={Item}/>
      <Route path="/store" component={Store}/>
     </Switch>
    </Router>
  );
}

export default App;